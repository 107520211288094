<template>
    <router-view />
</template>

<script>
import _ from 'lodash';
import Store from '@/store/index';

async function startGoogleLogin() {
    const PARAMETERS = {
        client_id: process.env.VUE_APP_GOOGLE_CLIENT_ID,
        scope: 'profile email',
        redirect_uri: `${process.env.VUE_APP_BASE_URL}super`,
        response_type: 'token',
        prompt: 'select_account',
    };
    const queryString = _.map(PARAMETERS, (v, k) => `${k}=${v}`).join('&');
    window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?${queryString}`;
}

export default {
    methods: {},
    async beforeRouteEnter(to, from, next) {
        const TOKEN = window.localStorage.getItem('token');
        const DISEASE = window.location.pathname.split('/')[1];
        let RESULT;
        if (TOKEN) {
            Store.commit('api/setToken', TOKEN);
            Store.commit('api/setDisease', 'super');
            const PAYLOAD = {
                category: 'Auth',
                function: 'verifyJwtToken',
                // successMsg: '歡迎回來',
            };
            RESULT = await Store.dispatch('api/invoke', PAYLOAD);
        } else {
            // startLoginFlow
            let ACCESS_TOKEN;
            if (window.location.hash) {
                const matches = window.location.hash.match(
                    'access_token=(?<token>.*?)&',
                );
                if (matches && matches.groups) {
                    ACCESS_TOKEN = matches.groups.token;
                }
            }

            if (!ACCESS_TOKEN) {
                startGoogleLogin();
            } else {
                Store.commit('api/setDisease', 'super');

                const SUBMIT = { token: ACCESS_TOKEN };
                console.log(SUBMIT);
                const PAYLOAD = {
                    category: 'Auth',
                    function: 'verifyOauthToken',
                    // successMsg: '登入成功',
                    data: SUBMIT,
                };
                RESULT = await Store.dispatch('api/invoke', PAYLOAD);
            }
        }
        if (!RESULT) {
            window.localStorage.clear();
            next((vm) => {
                vm.$router.push({ name: 'Login' });
                setTimeout(() => {
                    startGoogleLogin();
                }, 5000);
            });
            return;
        }

        window.localStorage.setItem('token', RESULT.data.token);
        Store.commit('api/setToken', RESULT.data.token);

        next((vm) => {
            const ACCOUNT_AUTH = RESULT.data.accountData.AccountAuths;
            const DISEASE_AUTH = _.find(ACCOUNT_AUTH, { DiseaseId: DISEASE });
            const isIdentityMatch = DISEASE_AUTH.auth === 'super';
            console.log(DISEASE_AUTH.auth);
            if (!isIdentityMatch) {
                window.localStorage.clear();
                vm.$router.push({ name: 'Login' });
                vm.$message({
                    message: '身份錯誤，請重新登入',
                    type: 'error',
                });
                setTimeout(() => {
                    startGoogleLogin();
                }, 5000);
            } else if (vm.$route.name !== 'Super_Console') {
                vm.$router.replace({ name: 'Super_Console' });
            }
        });
    },
};
</script>
